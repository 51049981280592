import React, {useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './otp.css'
import { verifyEmailApi } from '../../services/apis/authApi';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';

const Otp = ({stage, setStage}) => {

    const otpInputs = useRef([]);
    const [otp, setOtp] = useState(['', '', '', '']);
    const dispatch = useDispatch();
    const loading = useSelector(state => state.utilsObj.loading);
    const email = localStorage.getItem('email');


    const handleOtpChange = (index, value) => {
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);
        // Move focus to the next input field if value is entered
        if (value !== '' && index < otpInputs.current.length - 1) {
          otpInputs.current[index + 1].focus();
        }
      };
    
      const handleInput = (e, index) => {
        const { value } = e.target;
    
        if (!/^\d$|^$/.test(value)) return;
    
        if (value.length > 1) {
          e.target.value = value.slice(0, 1);
        }
    
        handleOtpChange(index, value);
        
      };
    
    
      const handleOtpSubmit = async (e) => {
        e.preventDefault();
        try {

          const enteredOtp = otp.join('');
          let data = {
            email: localStorage.getItem('email'),
            otp: enteredOtp
          }

          dispatch(requestSent());
          const response = await verifyEmailApi(data);

          if (response.status === 200) {
            dispatch(responseRecieved());
            localStorage.removeItem('email');
            toast.success(response.data.message);
            setStage('verified')
          }
          
        } catch (error) {
          console.log('error', error);
          dispatch(recievedError());
          if (error.response.status === 400 || error.response.status === 500) {
            return toast.error(error.response.data.error);
          } 
          return toast.error('Something went wrong');
        }
      }

  return (
    <div className='otp-container'>
    <div className="otp-box">
      <div className="otp-heading">
        <h3>
          Verify your email
        </h3>
        <p>
          Almost there we have sent Otp to {email ? email : '***@gmail.com'}
        </p>
      </div>
      <form onSubmit={handleOtpSubmit}>
        <div className="otp-feild-box">
          {otp.map((digit, index) => (
            <input
              key={index}
              type="text"
              maxLength={1}
              value={digit}
              onInput={(e) => handleInput(e, index)}
              ref={(el) => (otpInputs.current[index] = el)}
              required
            />
          ))}
        </div>
        <button className={!loading ? 'common-btn-style' : 'common-btn-disabled'} disabled={loading ? true : false}>Confirm</button>
      </form>
    </div>
  </div>
  )
}

export default Otp