import React from 'react'
import './navbar.css'
import {ENVS} from '../../utils/constant'

const Navbar = () => {
  return (
       <div className="navbar-container">
          <div className="logo">
            <img src={`${ENVS.REACT_APP_FRONTEND_IMG_PATH}/assets/icons/logo.png`} alt="logo" className='logo' />
          </div>
          {/* <div className="navbar-elements">
            <ul>
              <li>Services</li>
              <li>About Us</li>
              <li>Pricing</li>
            </ul>
          </div> */}
        </div>
  )
}

export default Navbar