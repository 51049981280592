import axios from "axios";
import { URI_PREFIX, ENVS } from "../../utils/constant";

const {REACT_APP_REQUEST_URL, REACT_APP_ENVIRONMENT} = ENVS;

const REQUEST_URL = REACT_APP_REQUEST_URL;
const env = REACT_APP_ENVIRONMENT;
const prefix = URI_PREFIX[env];

export const fetchAllSubscriptionsApi = async (token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/subscriptions`, token);
    return response;
}

export const checkOutSubscriptionApi = async (id, token) => {
    //this api is related to purchasing of subscription
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/subscription/checkout?subscription_id=${id}`, token)
    return response;
}

export const captureSubscriptionApi = async (id, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/subscription/capture?order_id=${id}`, token)
    return response;
}

export const cancelSubscriptionApi = async (id, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/subscription/cancel?order_id=${id}`, token);
    return response;
}

export const fetchPurchasedSubsriptionsApi = async (pageNo, filterData, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/subscription/purchased?page=${pageNo}&transaction_id=${filterData.transaction_id}&subscription_type=${filterData.subscription_type}&sort_by_date=${filterData.sort_by_date}`, token);
    return response;
}