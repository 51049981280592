import { createSlice } from "@reduxjs/toolkit";

const addCaseModalSlice = createSlice({
    name: 'addCaseModal',
    initialState:{
        isOpen: false,
        userDetails: null
    },
    reducers: {
        openAddCaseModal: (state, action) => {
            state.isOpen = true
            state.userDetails = action.payload
        },
        closeAddCaseModal: (state) => {
            state.isOpen = false
            state.userDetails = null
        }
    }
});

export const {openAddCaseModal, closeAddCaseModal} = addCaseModalSlice.actions;

export default addCaseModalSlice.reducer;