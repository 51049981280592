import React from 'react';
import './navigationButtons.css';
import {ENVS} from '../../utils/constant'

const NavigationButtons = ({ buttons }) => {

    return (
        <div className='flex-heading-container'>
            {buttons.map((button, index) => (
                <React.Fragment key={index}>
                    <button className={`case-request-btn ${button.isActive ? '' : 'inactive-focus'}`}>{button.label}</button>
                    {index < buttons.length - 1 && <img src={`${ENVS.REACT_APP_FRONTEND_IMG_PATH}/assets/icons/lessthan.png`} alt="lessthan" className='lessthan' />}
                </React.Fragment>
            ))}
        </div>
    );
}

export default NavigationButtons;
