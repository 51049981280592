import {toast} from 'react-toastify'
import { recievedError, requestSent, responseRecieved } from "../redux/slices/utilsSlice";
import { logOutApi } from "../services/apis/authApi";

export const dateFormatter = (date) => {
    const createdDate = new Date(date);
    // Extracting date components
    const year = createdDate.getFullYear();
    const month = createdDate.getMonth() + 1; // Month is zero-based, so we add 1
    const day = createdDate.getDate();
    // Formatting the date
    const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
    return formattedDate
}

export const generateFileName = (fileName, index) => {
    let suffix = index === 1 ? 'latest' : index === 2 ? '2nd' : index === 3 ? '3rd' : ''
    let newName = `${fileName} ${suffix}`
    return newName
}

export const handleLogOut = async (dispatch, token, navigate) => {
    try {
        dispatch(requestSent());
        const response = await logOutApi(token);
        if (response.status === 200) {
            dispatch(responseRecieved());
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('caseRemaining');
            localStorage.removeItem('userId');
            toast.success('Logged out successfully');
            navigate('/login');
        }
    } catch (error) {
        console.log('error', error);
        dispatch(recievedError(error));
        if (error.response.status === 400 || error.response.status === 500) {
            return toast.error(error.response.data.error);
        }
        return toast.error('Something went wrong');
    }
};