import axios from "axios";
import { URI_PREFIX, ENVS } from "../../utils/constant";

const {REACT_APP_REQUEST_URL, REACT_APP_ENVIRONMENT} = ENVS;

const REQUEST_URL = REACT_APP_REQUEST_URL;
const env = REACT_APP_ENVIRONMENT;
const prefix = URI_PREFIX[env];

export const loginApi = async (data) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/login`, data);
    return response;
}

export const signUpApi = async (data) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/signup`, data);
    return response;
}

export const verifyEmailApi = async (data) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/verify-email`, data);
    return response;
}

export const verifyUserStatusApi = async (token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/verify-user-status`, token);
    return response;
}

export const logOutApi = async (token) => {
    const response = await axios.put(`${REQUEST_URL}${prefix}/api/logout`, {}, token);
    return response;
}