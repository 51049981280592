import React from "react";
import {generateFileName} from "../.././utils/helper"

const FileUploadSection = ({ files,
    fileUploadStatus,
    handleFileChange,
    removeFile,
    sectionTitle,
    REACT_APP_FRONTEND_IMG_PATH,
    getFileName }) => {

    return (
        <div className="inp-files-container">
            {files.map((file, index) => (
                <div className="bankstatement-container" key={index}>
                    <div
                        className={`${fileUploadStatus[file] === "Pending" ? "pending" : "pending uploaded"
                            }`}
                    >
                        <img
                            src={
                                fileUploadStatus[file] === "Pending"
                                    ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/time.png`
                                    : `${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/checkmark.png`
                            }
                            alt="status"
                        />
                        <p className="document-name text-overflow-elipsis">
                            {getFileName(fileUploadStatus[file])}&nbsp;
                        </p>
                        {fileUploadStatus[file] !== "Pending" && (
                            <img
                                width="15"
                                className="cursor-pointer"
                                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/remove.png`}
                                alt="remove-file"
                                onClick={() => removeFile(file, fileUploadStatus[file])}
                            />
                        )}
                    </div>
                    <div className="doc-icon">
                        <img
                            src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png`}
                            alt="file-inp"
                            className="cloud-img"
                        />
                        <p style={{ fontSize: "12px" }}>
                            {/* {sectionTitle} {index + 1} */}
                            {generateFileName(sectionTitle, index + 1)}
                        </p>
                        <div className="file-icon-btn">
                            <img
                                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`}
                                alt="file-inp"
                            />
                            <span>Upload pdf</span>
                        </div>
                    </div>
                    <input
                        type="file"
                        name={file}
                        onChange={handleFileChange}
                        className="upload-pdf-inp"
                        accept=".pdf"
                        // required={index === 0 && fileUploadStatus[file] === "Pending"}
                    />
                </div>
            ))}
        </div>
    );
};

export default FileUploadSection;
