import React, { useState, useEffect, useRef } from 'react';
import './subscriptionModal.css';
import { closeSubscriptionModal } from '../../redux/slices/subscriptionModalSlice';
import { useDispatch } from 'react-redux';
import { USER_ROLES, ENVS } from '../../utils/constant';

const SubscriptionModal = ({ navigate }) => {

  const dispatch = useDispatch();
  const [animate, setAnimate] = useState(false);
  const modalRef = useRef();
  const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

  useEffect(() => {
    setAnimate(true)
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setAnimate(false); //slides back the modal towards center
        //set time out is to to ensure that the slide-out animation completes before the modal is closed
        setTimeout(() => {
          dispatch(closeSubscriptionModal())
        }, 500)
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [])

  return (
    <div className='custom-modal-container'>
      <div className={`custom-modal-content ${animate ? 'slide-from-left' : ''}`} ref={modalRef}>
        <div className='custom-modal-body'>
          <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/halfcyl1.png`} alt="halfcyl1" className='halfcyl1' />
          <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/halfcyl2.png`} alt="halfcyl2" className='halfcyl2' />
          <div className='subs-content'>
            <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/behindrect.png`} alt="behindrect" className='behind-rectangle' />
            <h1 className='subscribe-heading'>Subscribe</h1>
            <h3 className='subscription-sub-heading'>Subscribe our plan to create a new case</h3>
            {
              localStorage.getItem('role') === USER_ROLES.BROKER && (
                <button className='buy-now-subs'
                  onClick={() => {
                    navigate('/subscription');
                    dispatch(closeSubscriptionModal());
                  }
                  }>Buy Now</button>
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionModal