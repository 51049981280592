import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { authToken, authTokenFormData } from '../../services/token';
import { toast } from 'react-toastify';
import { requestSent, responseRecieved, recievedError } from '../../redux/slices/utilsSlice';
import './createUpdateStaff.css'
import { fetchStaffMemberApi, updateStaffApi, updateStaffStatusApi } from '../../services/apis/brokerApi';
import { USER_STATUS } from '../../utils/constant';
import { ENVS } from '../../utils/constant';

const UpdateStaff = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const jsonAuthToken = authToken();
  const formAuthToken = authTokenFormData();
  const loading = useSelector((state) => state.utilsObj.loading);
  const searchParams = new URLSearchParams(location.search);
  const staffId = searchParams.get('staff_id');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);

  const [updateStaffData, setUpdateStaffData] = useState({
    username: '',
    email: '',
    password: '',
    contact: '',
    status: '',
    address: ''
  });
  const [showPic, setShowPic] = useState(null);
  const {REACT_APP_FRONTEND_IMG_PATH, REACT_APP_REQUEST_URL} = ENVS;

  useEffect(() => {
    fetchStaffMemberDetails()
  }, []);

  const fetchStaffMemberDetails = async () => {

    try {

      dispatch(requestSent());
      const response = await fetchStaffMemberApi(staffId, jsonAuthToken);
      if (response.status === 200) {

        const data = response.data.data

        setUpdateStaffData({
          username: data.username,
          email: data.email,
          password: data.password,
          contact: data.phone,
          address: data.address,
          status: data.status,
          picture: data.profile_pic
        })
        dispatch(responseRecieved());
        return;
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }
  }


  const handleChange = (e) => {

    if (e.target.name === 'picture') {
      // Handle file input change
      setUpdateStaffData({
        ...updateStaffData,
        [e.target.name]: e.target.files[0] // Store the selected file
      });
      setShowPic(URL.createObjectURL(e.target.files[0]));

    } else {
      // Handle text input change
      setUpdateStaffData({
        ...updateStaffData,
        [e.target.name]: e.target.value
      });
    }
  };

  const handleUpdate = async (e) => {
    try {

      e.preventDefault();

      const formData = new FormData();
      formData.append('username', updateStaffData.username);
      formData.append('email', updateStaffData.email);
      formData.append('password', updateStaffData.password);
      formData.append('contact', updateStaffData.contact);
      formData.append('address', updateStaffData.address);

      if (showPic !== null) {
        //pic has been changed
        formData.append('picture', updateStaffData.picture); // Append the file to form data
      }

      dispatch(requestSent());

      const response = await updateStaffApi(staffId, formData, formAuthToken);

      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        fetchStaffMemberDetails();
        return;
      }

    } catch (error) {

      console.log('error', error);
      dispatch(responseRecieved());

      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');

    }

  }


  const handleDeleteClick = () => {
    setConfirmationAction(USER_STATUS.DELETED);
    setShowConfirmation(true);
  };

  const handleBlockClick = () => {
    setConfirmationAction(USER_STATUS.BLOCKED);
    setShowConfirmation(true);
  };

  const confirmAction = () => {
    if (confirmationAction) {
      handleStatusUpdate(confirmationAction);
    }
    setShowConfirmation(false);
  };

  const cancelAction = () => {
    setConfirmationAction(null);
    setShowConfirmation(false);
  };


  const handleStatusUpdate = async (incomingStatus) => {

    try {

      const data = {
        status: incomingStatus
      }

      dispatch(requestSent());
      const response = await updateStaffStatusApi(staffId, data, jsonAuthToken);
      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        navigate('/staff');
        return;
      }

    } catch (error) {

      console.log('error=>', error);

      dispatch(responseRecieved());
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }

  }

  return (
    <>
      <UserNavbar />

      <div className="common-page">
        <div className='account-box-header'>
          <div className='flex-div'>
            <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '16px', cursor: 'pointer' }}
              onClick={() => navigate(-1)}
            /><h1>Edit Staff Member</h1>
          </div>
          <div className='block-delete-btn-container'>
            <button
              onClick={() => handleDeleteClick(USER_STATUS.DELETED)}
              className='delete-btn'
              disabled={loading ? true : false}
            >Delete</button>
            <button
              onClick={() => handleBlockClick(USER_STATUS.BLOCKED)}
              className='block-btn'
              disabled={loading ? true : false}
            >{updateStaffData.status === 'Active' ? 'Block' : 'Unblock'}</button>
          </div>
        </div>

        {showConfirmation && (
          <div className="modal-container">
            <div className='confirmation-card'>
              <p>Are you sure you want to perform this action?</p>
              <button onClick={confirmAction} className='confirm-cancel-btn'>Confirm</button>
              <button onClick={cancelAction} className='confirm-cancel-btn'>Cancel</button>
            </div>
          </div>
        )}


        <form className='signup-form' style={{ marginTop: '1rem' }} onSubmit={handleUpdate} method='POST' encType='multipart/form-data'>
          <div className="form-feilds-container">
            <div className="form-left-feilds">
              <label htmlFor="username" className='staff-form-label'>Name</label>
              <input type="text" name="username" placeholder="Staff Member's Name" className='staff-input'
                value={updateStaffData.username} onChange={handleChange}
                disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} required />
              <label htmlFor="username" className='staff-form-label'>Email</label>
              <input type="email" name="email" placeholder="Staff Member's Email" className='staff-input'
                value={updateStaffData.email} onChange={handleChange}
                disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} required />
              <label htmlFor="password" className='staff-form-label'>Password</label>
              <input type="password" name="password" placeholder="Password" className='staff-input' value={updateStaffData.password} onChange={handleChange}
                disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} required />
              <label htmlFor="contact" className='staff-form-label'>Contact Number</label>
              <input type="number" name="contact" placeholder="Staff Member's Contact Number" className='staff-input' value={updateStaffData.contact} onChange={handleChange}
                disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} required />
            </div>
            <div className="form-right-feilds" >
              <label htmlFor="address" className='staff-form-label'>Address</label>
              <textarea placeholder="Staff Member's Address" name='address' rows={7} className='staff-input' value={updateStaffData.address} onChange={handleChange}
                disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} required></textarea>
              <div className="profile-pic-container">
                <input type="file" name='picture' className='pic-input' onChange={handleChange} accept='.jpeg, .jpg, .webp, tiff, .png, .gif, .avif, .heic' disabled={updateStaffData.status === USER_STATUS.ACTIVE ? false : true} />
                <div className='pic-icon'>
                  <img src={showPic === null ? `${REACT_APP_REQUEST_URL}${updateStaffData.picture}` : showPic}
                    alt="file-inp"
                    className='upload-pic-lg'
                  />
                </div>
                <div className="pic-text">
                  Change Image
                </div>

                <div className='file-icon-btn'>
                  <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`} alt="file-inp" />
                  <span>Upload</span>
                </div>
              </div>
              <div>
                <button className={`staff-action-btn ${loading ? 'staff-btn-disabled' : ''}`} disabled={loading ? true : false}> {loading && <span className='btn-spinner'></span>} Save Changes</button>
                {/* <p className='staff-one-liner'>An email notification will be sent to the newly added staff member</p> */}
              </div>
            </div>
          </div>
        </form>

      </div>
    </>
  )
}

export default UpdateStaff