import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';
import { authToken } from '../../services/token';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchSingleUserApi } from '../../services/apis/userApi';
import Loader from '../loader/Loader';


const StaffInfo = ({ showStaffInfo, setShowStaffInfo, loading }) => {


  const token = authToken();
  const modalRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [staffInfoData, setStaffInfoData] = useState(null);


  useEffect(() => {

    fetchUserDetails();

    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        if (!loading) {
          setShowStaffInfo(false);        } 
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };

  }, [showStaffInfo]);

  const fetchUserDetails = async () => {

    try {

      dispatch(requestSent());
      const response = await fetchSingleUserApi(token);
      
      if (response.status === 200) {
        dispatch(responseRecieved());
        setStaffInfoData(response.data.data)
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }
  }

  return (
    <>
      <div className="modal-container">
        <div className="modal-content" ref={modalRef}>
          {
            loading ? (<Loader />) : (
              <>
                <div className='modal-heading'>
                  <h2>Staff Info</h2>
                  <span className='close-icon' onClick={() => setShowStaffInfo(!showStaffInfo)}>X</span>
                </div>
                <p>Name: {staffInfoData?.username}</p>
                <p>Email: {staffInfoData?.email}</p>
                <p>Case created: {staffInfoData?.cases_created}</p>
                <p>Case approved: {staffInfoData?.cases_approved}</p>
                <p>Case rejected: {staffInfoData?.cases_rejected}</p>
              </>
            )
          }
        </div>
      </div>
    </>
  )
}

export default StaffInfo