import { createSlice } from "@reduxjs/toolkit";

let initialState = {
    loading: false,
    error: ''
};


const utilsSlice = createSlice({
    name: 'utils',
    initialState,
    reducers: {
        requestSent: (state, action) => {
            state.loading = true
        },
        responseRecieved: (state, action) => {
            state.loading = false
        },
        recievedError: (state, action) => {
            state.loading = false
            state.error = action.payload
        }
    },
});

export const { requestSent, responseRecieved, recievedError } = utilsSlice.actions;


export default utilsSlice.reducer;