import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './transaction.css';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { authToken } from '../../services/token';
import { fetchPurchasedSubsriptionsApi } from '../../services/apis/subscriptionApi';
import { dateFormatter } from '../../utils/helper';
import { ENVS } from '../../utils/constant';
import Loader from '../../components/loader/Loader';

const Transaction = () => {


    const [transactionData, setTransactionData] = useState([]);
    const [paginationData, setPaginationData] = useState({
        pageNo: 1,
        limit: 10,
        totalPages: 0
    });
    const [filterData, setFilterData] = useState({
        transaction_id: "",
        subscription_type: "",
        sort_by_date: ""
    });


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = authToken();
    const loading = useSelector((state) => state.utilsObj.loading);

    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

    useEffect(() => {
        fetchPurchasedSubscriptions();
    }, [paginationData.pageNo]);

    const fetchPurchasedSubscriptions = async () => {
        try {

            dispatch(requestSent());

            const response = await fetchPurchasedSubsriptionsApi(paginationData.pageNo, filterData, token);
            if (response.status === 200) {
                setTransactionData(response.data.data.rows);
                setPaginationData({
                    pageNo: response.data.pageNo,
                    limit: response.data.perPage,
                    totalPages: response.data.totalPages
                });

                dispatch(responseRecieved())
            }

        } catch (error) {

            console.log('error=>', error);
            dispatch(recievedError(error))
            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            }
            return toast.error('Something went wrong');
        }
    }

    const handlePageClick = (data) => {
        const updatedPageNo = data.selected + 1; // Pagination starts from 0, so adding 1
        setPaginationData({ ...paginationData, pageNo: updatedPageNo });
    };

    const handleFilterChange = (e) => {

        const { name, value } = e.target
        setFilterData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }

    const handleSearchSubmit = (e) => {
        fetchPurchasedSubscriptions()
    }

    return (
        loading ? <Loader/> : (
        <>
            <UserNavbar />
            <div className="common-page">
                <div className='account-box-header'>
                    <div className='flex-div'>
                        <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan"
                            onClick={() => navigate(-1)} style={{ cursor: 'pointer', marginRight: '8px' }} /><h1 className='main-subheading'>Transaction History</h1>
                    </div>
                </div>


                <div className="filter-box">
                    <div className="input-box">
                        <input type="text" placeholder='Search by transaction id' name='transaction_id' className='filter-input' onChange={handleFilterChange} value={filterData.transaction_id} />
                    </div>
                    <div className="input-box">
                        <input type="text" placeholder='Search by subscription type' name='subscription_type'
                            className='filter-input' onChange={handleFilterChange} value={filterData.subscription_type} />
                    </div>
                    <div className="select-box">
                        <select name="sort_by_date" className='filter-input' onChange={handleFilterChange} value={filterData.sort_by_date}>
                            <option value="">Sort By Date</option>
                            <option value="DESC">Latest</option>
                            <option value="ASC">Oldest</option>
                        </select>
                    </div>
                    <div className="button-box">
                        <button type='button' className='filter-submit' onClick={handleSearchSubmit}>Search</button>
                    </div>
                </div>
                <div className="table-container">
                    {
                        transactionData.length === 0 ?
                            (
                                <div className="no-data">
                                    <h1>No Transaction History Found</h1>
                                </div>
                            ) : (
                                <table className='transaction-table'>
                                    <thead>
                                        <tr className='table-row'>
                                            <th className='table-head'>S.No.</th>
                                            <th className='table-head'>Transaction Id</th>
                                            <th className='table-head'>Subscription type</th>
                                            <th className='table-head'>Date</th>
                                            <th className='table-head'>Amount</th>
                                            <th className='table-head'>Total case</th>
                                            <th className="table-head">Payment Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {transactionData.map((val, index) => {
                                            return (
                                                <tr className='table-row' key={index}>
                                                    <td className="table-data">{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                                                    <td className="table-data">{val?.transaction_id}</td>
                                                    <td className="table-data">{val?.Subscription?.title}</td>
                                                    <td className="table-data">{dateFormatter(val?.createdAt)}</td>
                                                    <td className="table-data">£ {val?.amount}</td>
                                                    <td className="table-data">{val?.total_case}</td>
                                                    <td className="table-data"> <span className={`${val.payment_status === 'COMPLETED' ? 'custom-badge-success' : 'custom-badge-error'}`}>{val?.payment_status}</span></td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            )
                    }

                    {
                        transactionData.length > 0 &&
                        <ReactPaginate
                            pageCount={paginationData.totalPages}
                            pageRangeDisplayed={2}
                            marginPagesDisplayed={3}
                            forcePage={paginationData.pageNo - 1 }
                            onPageChange={handlePageClick}
                            containerClassName={'pagination'}
                            activeClassName={'active'}
                        />
                    }
                </div>


            </div>
        </>

        )
    );

}

export default Transaction