import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import './login.css'
import { toast } from 'react-toastify';
import { loginApi } from '../../services/apis/authApi';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { setRemainingCase } from '../../redux/slices/caseRemainingSlice';

const Login = () => {

  const [loginData, setLoginData] = useState({
    email: '',
    password: ''
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.utilsObj.loading);


  const handleInputChange = (e) => {

    const { name, value } = e.target;
    setLoginData(prevState => ({
      ...prevState,
      [name]: value
    }));
  }

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {

      let data = {
        email: loginData.email,
        password: loginData.password
      }

      dispatch(requestSent());

      const response = await loginApi(data);

  
      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        localStorage.setItem('token', response.data.data.reset_token);
        localStorage.setItem('role', response.data.data.role);
        localStorage.setItem('userId', response.data.data.id);
        dispatch(setRemainingCase(response.data.data.case_remaining));
        navigate('/home')
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));

      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      }
      return toast.error('Something went wrong');
    }
  }

  return (
    <div className='login-box-container'>
      <div className="login-box">
        <p className="sign-in">
          Sign in
        </p>
        <form className='login-form' onSubmit={handleLoginSubmit} method="POST">
          <div className='email-container'>
            <label htmlFor="email" className='common-label-style'>Email</label>
            <input type="text" name="email" placeholder="Email" className='common-input-style' value={loginData.email} onChange={(e) => handleInputChange(e)} required />
          </div>
          <div>
            <label htmlFor="email" className='common-label-style'>Password</label>
            <input type="password" name="password" placeholder="Password" className='common-input-style' value={loginData.password} onChange={(e) => handleInputChange(e)} required />
          </div>
          <button className={!loading ? 'common-btn-style' : 'common-btn-disabled'} disabled={loading ? true : false} >
            Login
          </button>
        </form>
        <div style={{ textAlign: 'center' }}>
          <p style={{ marginBottom: '8px', fontFamily: 'Gilroy-Bold' }}>Don't you have an account? <span className='link-text' onClick={() => navigate('/signup')}>Sign Up</span></p>
        </div>
      </div>
    </div>

  )
}

export default Login