import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import './signup.css'
import Otp from '../../components/otp/Otp';
import VerificationSuccess from '../../components/verificationSuccess/VerificationSuccess';
import { STAGES, USER_ROLES } from '../../utils/constant';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { signUpApi } from '../../services/apis/authApi';
import { setRemainingCase } from '../../redux/slices/caseRemainingSlice';
import { ENVS } from '../../utils/constant';

const SignUp = () => {

  const [stage, setStage] = useState(STAGES.BASIC_DETAILS); // Initially, stage is 'basicDetails'

  const [userData, setUserData] = useState({
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    contact: '',
    companyName: '',
    fcaNumber: ''
  });
  const dispatch = useDispatch();
  const loading = useSelector(state => state.utilsObj.loading);
  const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    
    const { name, value } = event.target;
    setUserData(prevUserData => ({ ...prevUserData, [name]: value }));
  }

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    try {

      dispatch(requestSent())

      const response = await signUpApi(userData);
      if (response.status === 200) {
        dispatch(responseRecieved());
        toast.success(response.data.message);
        localStorage.setItem('token', response.data.data.reset_token);
        localStorage.setItem('role', USER_ROLES.BROKER);
        localStorage.setItem('email',  response.data.data.email);
        localStorage.setItem('userId', response.data.data.id);
        dispatch(setRemainingCase(response.data.data.case_remaining));
        setStage(STAGES.VERIFICATION);
      }
    } catch (error) {
      console.log('error', error);
      dispatch(recievedError());
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } 
      return toast.error('Something went wrong');
    }
  }


  return (

    <div className="signup-box-container">
      <div className="signup-box">

        {
          stage !== STAGES.VERIFIED &&
          <div className="signup-header-container">
            <div className="signup-header">
              <div className="left-heading">
                <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="img" style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} />
              </div>
              <div style={{ marginBottom: '8px', fontFamily:'Gilroy-Bold' }}>
                Already a Member? <span className='link-text' onClick={() => navigate('/login')}>Sign In</span>
              </div>
            </div>
            <p className='sign-up'>
              Sign Up
            </p>

            <div className="activity-entity-container">
              <div className="active-entity">
                <div className="basic-details">
                  <div className={`number ${stage === STAGES.BASIC_DETAILS ? 'number-active' : ''}`}>1</div>
                  <div className={`text ${stage === STAGES.BASIC_DETAILS ? 'text-active' : ''}`}>Basic Details</div>
                </div>

                <div className="rectangle">
                  <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/rectangle3.png`} alt="img" />
                </div>

                <div className="verification">
                  <div className={`number ${stage === STAGES.VERIFICATION ? 'number-active' : ''}`}>2</div>
                  <div className={`text ${stage === STAGES.VERIFICATION ? 'text-active' : ''}`}>Verification</div>
                </div>
              </div>
            </div>
          </div>

        }

        {/**Header ends */}

        {stage === STAGES.BASIC_DETAILS ? (
          <form className='signup-form' onSubmit={handleSignupSubmit}>
            <div className='form-feilds-container'>
              <div className="form-left-feilds">
                <label htmlFor="username" className='common-label-style'>Enter Username</label>
                <input type="text" name="username" placeholder="User name" className='common-input-style custom-input-margin' value={userData.username} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="email" className='common-label-style' >Enter Email ID</label>
                <input type="email" name="email" placeholder="Email ID" className='common-input-style custom-input-margin' value={userData.email} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="password" className='common-label-style'>Enter Password</label>
                <input type="password" name="password" placeholder="Password" className='common-input-style custom-input-margin' value={userData.password} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="confirmPassword" className='common-label-style'>Confirm Password</label>
                <input type="password" name="confirmPassword" placeholder="Confirm Password" className='common-input-style custom-input-margin' value={userData.confirmPassword} onChange={(e) => handleInputChange(e)} required/>
              </div>
              <div className="form-right-feilds">
                <label htmlFor="contact" className='common-label-style'>Enter Contact Number</label>
                <input type="number" name="contact" placeholder="Contact Number" className='common-input-style custom-input-margin' value={userData.contact} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="companyName" className='common-label-style'>Enter Company Number</label>
                <input type="text" name="companyName" placeholder="Company Number" className='common-input-style custom-input-margin' value={userData.companyName} onChange={(e) => handleInputChange(e)} required/>
                <label htmlFor="fcaNumber" className='common-label-style'>Enter FCA Number</label>
                <input type="text" name="fcaNumber" placeholder="Enter Fca Number" className='common-input-style custom-input-margin' value={userData.fcaNumber} onChange={(e) => handleInputChange(e)} required/>
              </div>
            </div>
            <button className={!loading ? 'common-btn-style' : 'common-btn-disabled'} disabled={loading ? true : false} >
                Save & continue
            </button>
          </form>
        ) : stage === STAGES.VERIFICATION ? (
          <Otp stage={stage} setStage={setStage} />
        ) : stage === STAGES.VERIFIED ? (
          <VerificationSuccess />
        ) : null
        }

      </div>
    </div>
  );
}

export default SignUp;
