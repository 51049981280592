import React, { useEffect, useRef, useState } from 'react';
import './addCaseModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { authToken } from '../../services/token';
// import RemainingCases from '../remainingCase/RemainingCase';
import { closeAddCaseModal } from '../../redux/slices/addCaseModalSlice';
import Loader from '../loader/Loader'
import { toast } from 'react-toastify';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { allocateCasesApi } from '../../services/apis/brokerApi';
import { useNavigate } from 'react-router-dom';

const AddCaseModal = ({fetchStaff}) => {

    const modalRef = useRef();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const token = authToken();
    const userDetails = useSelector(state => state.addCaseModalObj.userDetails);
    const loading = useSelector((state) => state.utilsObj.loading);
    const [totalCases, setTotalCases] = useState(0);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                if (!loading) {
                    dispatch(closeAddCaseModal());
                }
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleCaseChange = (event) => {

        const {value} = event.target;
        setTotalCases(value);
    }


    const handleAllotCase = async () => {

        try {   

            if (isNaN(totalCases)) {
                return toast.error('Total Cases must be a number')
            }

            const data = {
                totalCase: Number(totalCases)
            }

            dispatch(requestSent());
            const response = await allocateCasesApi(userDetails.id, data, token);

            if (response.status === 200) {
                toast.success(response.data.message);
                dispatch(responseRecieved());
                dispatch(closeAddCaseModal());
                fetchStaff();
            }
            
        } catch (error) {
            
            console.log('error=>', error);
            dispatch(recievedError());            
            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            return toast.error('Something went wrong');

        }
    }

    return (
        <>
            <div className="modal-container">
                <div className="modal-content" ref={modalRef}>
                    {loading ? <Loader /> : (
                        <>
                            <div className='modal-heading'>
                                <h2>Add Cases</h2>
                                <span className='close-icon'
                                    onClick={() => {
                                        dispatch(closeAddCaseModal())
                                    }}
                                >X</span>
                            </div>
                            <p>Name: {userDetails.username}</p>
                            <p>Email: {userDetails.email}</p>
                            <p>Cases Remaining: {userDetails.case_remaining}</p>

                            <div className='add-case-form'>
                                <label htmlFor='totalCase'>Add number of case checks</label>
                                <input type='number' 
                                name='totalCases' 
                                className='broad-add-case-input' 
                                value={totalCases} 
                                onChange={handleCaseChange} 
                                placeholder='Assign Case' />
                                <button className='add-case-btn' onClick={handleAllotCase}>Add</button>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    )
}

export default AddCaseModal