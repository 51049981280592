import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './home.css'
import { openSubscriptionModal } from '../../redux/slices/subscriptionModalSlice';
import SubscriptionModal from '../../components/subscriptionAdModal/SubscriptionModal';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { authToken } from '../../services/token';
import { toast } from 'react-toastify';
import { fetchRecentCasesApi } from '../../services/apis/caseApi';
import { dateFormatter } from '../../utils/helper';
import { fetchRemainingCasesApi } from '../../services/apis/userApi';
import Loader from '../../components/loader/Loader';
import StaffInfo from '../../components/staffDetailsModal/StaffInfo';
import { USER_ROLES, ENVS } from '../../utils/constant';



const Home = () => {
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const subscriptionModalState = useSelector(state => state.subscriptionModalObj.isSubscripionModalSliceOpen);
  const loading = useSelector((state) => state.utilsObj.loading);
  const [recentCases, setRecentCases] = useState([]);
  const [remainingCases, setRemainingCases] = useState(null);
  const [showStaffInfo, setShowStaffInfo] = useState(false);
  const token = authToken();
  const role = localStorage.getItem('role');
  const { REACT_APP_FRONTEND_IMG_PATH } = ENVS;

  useEffect(() => {
    fetchRecentCases();
    fetchRemainingCases();
  }, []);


  const handleStartNewCase = () => {    
    if (remainingCases == 0) {
      dispatch(openSubscriptionModal());
    } else if (remainingCases > 0) {
      navigate('/create-case');
    } else {
      toast.error('Something went wrong');
    }
  };

  const fetchRemainingCases = async () => {
    try {

      dispatch(requestSent());
      const response = await fetchRemainingCasesApi(token);

      if (response.status === 200) {
        setRemainingCases(response.data.data);
        dispatch(responseRecieved());
        return;
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      }
      return toast.error('Something went wrong');
    }
  }

  const fetchRecentCases = async () => {

    try {

      dispatch(requestSent());
      const response = await fetchRecentCasesApi(token);
      if (response.status === 200) {
        setRecentCases(response.data.data);
        dispatch(responseRecieved());
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(recievedError(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      }
      return toast.error('Something went wrong');
    }
  }

  return (
    <>
      {/* show subscription modal if 0 cases remaining */}
      {subscriptionModalState && <SubscriptionModal navigate={navigate} />}

      {/* staff info  */}
      {showStaffInfo && <StaffInfo showStaffInfo={showStaffInfo} setShowStaffInfo={setShowStaffInfo} loading={loading} />}

      {
        loading ? (
          <Loader />
        ) : (
          <>

            <UserNavbar />
            <div className='common-container'>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className='case-request-btn'>Case Requests</button>
                {
                  role === USER_ROLES.STAFF && (
                    <img
                      src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/account.png`}
                      alt="account"
                      className='account-logout-image'
                      onClick={() => setShowStaffInfo(!showStaffInfo)}
                      style={{cursor: 'pointer'}}
                      ></img>
                  )
                }
              </div>

              <div className="cases-box">
                <div className='cases-box-header'>
                  <div>
                    <h1>Request Cases</h1>
                    <p className='case-one-liner'>Fast, easy and secure</p>
                    <p>We need some information to get started. It's fast and easy. We only ask for what we need.</p>
                  </div>
                  <div>
                    <p className='remaining-cases'>Remaining cases: <span>{remainingCases}</span></p>
                  </div>
                </div>

                <div className='start-new-case'>
                  <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/plus.png`} alt="plusicon" onClick={handleStartNewCase} />
                  <p>Start new case</p>
                </div>

                <div className='recent-cases' style={{ display: 'flex', alignItems: 'center' }}>
                  <p style={{ fontFamily: 'Gilroy-Bold' }}>Recent cases</p>
                  <div style={{ marginTop: '4px', marginLeft: '5px' }} ><img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/rightarrow.png`} alt="rightarrow" onClick={() => navigate('/cases-history')} style={{ cursor: 'pointer' }} /></div>
                </div>

                <div className="recent-cases-list">
                  {
                    recentCases.length > 0 ? (
                      recentCases.map((val, index) => {
                        return (
                          <div className="case-list-items" key={index}>
                            <div>
                              <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/casefile.png`} alt="case-file" style={{ cursor: 'pointer' }} onClick={() => navigate(`/case-detail?id=${val.id}`)} />
                            </div>
                            <div className="case-item-title">
                              <p> Case <span>{val.name}</span></p>
                              <p className='sub-date'>Submitted on {dateFormatter(val.createdAt)}</p>
                            </div>
                          </div>
                        )
                      })
                    ) : (
                      <div>
                        No Case History Found
                      </div>
                    )
                  }
                </div>
              </div>

            </div>
          </>

        )
      }
    </>
  )
}

export default Home