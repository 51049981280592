import React from 'react';
import moment from 'moment';

const CaseCard = ({ caseDetail, userId, caseId, navigate, errorNA }) => {

  return (
    <div className="case-child-one">
      <div className="case-card">
        <div className="case-card-left">
          <div className='custom-mb'>
            <span className='card-span'>Name:</span>{caseDetail.name}
          </div>
          <div className='custom-mb'>
            <span className='card-span'>Broker Name:</span>{caseDetail.broker_name || errorNA}
          </div>
          <div className='custom-mb'>
            <span className='card-span'>Employer Name:</span>{caseDetail.employer_name || errorNA}
          </div>
          <div className='custom-mb'>
            <span className='card-span'>Date Created:</span>
            {moment(caseDetail.createdAt).format('llll')}
          </div>

          {caseDetail.CaseAddresses && (
            <div>
              <div className='custom-mb'>
                <span className='card-span' style={{ textDecoration: 'underline' }}>Case Addresses</span>
              </div>
              {caseDetail.CaseAddresses.map((address, index) => (
                <div key={index} className='custom-mb'>
                  <p className='custom-mb'><span className='card-span'>Address {index + 1}:</span></p>
                  <p><span className='card-span'>Place</span>{address.address}</p>
                  <p style={{ textTransform: 'capitalize' }}><span className='card-span'>Address Type: </span>{address.address_type}</p>
                  <p><span className='card-span'>Move in Date:</span> {address.move_in_date}</p>
                  <p><span className='card-span'>Move out Date:</span> {address.move_out_date}</p>
                </div>
              ))}
            </div>
          )}

          {caseDetail.approval_status === 'pending' && caseDetail.requester_id === userId && (
            <div className='custom-mb'>
              <br />
              <p className='card-span custom-mb'>You have not uploaded documents for this case *</p>
              <button
                className='save-case-btn'
                onClick={() => navigate(`/docs-upload?case_request_id=${caseId}`)}
              >
                Upload Documents
              </button>
            </div>
          )}
        </div>

        <div className="case-card-right">
          <div className="card-score">
            <h1>Score</h1>
            <h1>{caseDetail.score === null ? 'Not Available' : `${caseDetail.score}%`}</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaseCard;
