import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { authTokenFormData } from '../../services/token';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './createUpdateStaff.css'
import { createStaffApi } from '../../services/apis/brokerApi';
import { toast } from 'react-toastify';
import { requestSent, responseRecieved, recievedError } from '../../redux/slices/utilsSlice';
import { ENVS } from '../../utils/constant';

const CreateStaff = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const token = authTokenFormData();
    const loading = useSelector((state) => state.utilsObj.loading);
    const [showPic, setShowPic] = useState('');
    const [createStaffData, setCreateStaffData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
        contact: '',
        address: '',
        picture: null
    });
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

    const handleChange = (e) => {

        if (e.target.name === 'picture') {
            // Handle file input change
            setCreateStaffData({
                ...createStaffData,
                [e.target.name]: e.target.files[0] // Store the selected file
            });
            setShowPic(URL.createObjectURL(e.target.files[0]));

        } else {
            // Handle text input change
            setCreateStaffData({
                ...createStaffData,
                [e.target.name]: e.target.value
            });
        }
    };


    const handleSubmit = async (e) => {
        try {
            e.preventDefault();
            const formData = new FormData();
            formData.append('username', createStaffData.username);
            formData.append('email', createStaffData.email);
            formData.append('password', createStaffData.password);
            formData.append('confirmPassword', createStaffData.confirmPassword);
            formData.append('contact', createStaffData.contact);
            formData.append('address', createStaffData.address);
            formData.append('picture', createStaffData.picture); // Append the file to form data

            dispatch(requestSent());
            const response = await createStaffApi(formData, token);
            if (response.status === 200) {
                dispatch(responseRecieved());
                toast.success(response.data.message);
                navigate(`/staff`);
                return;
            }

        } catch (error) {

            console.log('error=>', error);
            dispatch(recievedError(error));
            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            return toast.error('Something went wrong');
        }
    }


    return (
        <>
            <UserNavbar />
            <div className='common-page'>
                <div className='account-box-header'>
                    <div className='flex-div'>
                        <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '1.5rem', cursor: 'pointer' }} onClick={() => navigate(-1)} />
                        <h1>Add Staff Member</h1>
                    </div>
                </div>


                <form className='signup-form' style={{ marginTop: '1rem' }} onSubmit={handleSubmit} method='POST' encType='multipart/form-data'>
                    <div className="form-feilds-container">
                        <div className="form-left-feilds">
                            <label htmlFor="username" className='staff-form-label'>Name</label>
                            <input type="text" name="username" placeholder="Staff Member's Name" className='staff-input' value={createStaffData.username} onChange={handleChange} required />
                            <label htmlFor="username" className='staff-form-label'>Email</label>
                            <input type="email" name="email" placeholder="Staff Member's Email" className='staff-input' value={createStaffData.email} onChange={handleChange} required />
                            <label htmlFor="password" className='staff-form-label'>Create a Password</label>
                            <input type="password" name="password" placeholder="Password" className='staff-input' value={createStaffData.password} onChange={handleChange} required />
                            <label htmlFor="confirmPassword" className='staff-form-label'>Confirm Password</label>
                            <input type="password" name="confirmPassword" placeholder="Confirm Password" className='staff-input' value={createStaffData.confirmPassword} onChange={handleChange} required />
                            <label htmlFor="contact" className='staff-form-label'>Contact Number</label>
                            <input type="number" name="contact" placeholder="Staff Member's Contact Number" className='staff-input' value={createStaffData.contact} onChange={handleChange} required />
                        </div>
                        <div className="form-right-feilds" >
                            <label htmlFor="address" className='staff-form-label'>Address</label>
                            <textarea placeholder="Staff Member's Address" name='address' rows={7} className='staff-input' value={createStaffData.address} onChange={handleChange} required></textarea>
                            <div className="profile-pic-container">
                                <input type="file" name='picture' className='pic-input' onChange={handleChange} accept='.jpeg, .jpg, .webp, tiff, .png, .gif, .avif, .heic' required />
                                <div className='pic-icon'>
                                    <img src={createStaffData.picture === null ? `${REACT_APP_FRONTEND_IMG_PATH}/assets/images/docicon.png` : showPic } alt="file-inp" className='upload-pic-lg' />

                                </div>
                                <div className="pic-text">
                                    Upload Image
                                </div>

                                <div className='file-icon-btn'>
                                    <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/document-text.png`} alt="file-inp" />
                                    <span>Upload</span>
                                </div>
                            </div>
                            <div>
                                <button className={`staff-action-btn ${loading ? 'staff-btn-disabled' : ''}`} disabled={loading ? true : false}> {loading && <span className='btn-spinner'></span>}Add Staff</button>
                                <p className='staff-one-liner'>An email notification will be sent to the newly added staff member</p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default CreateStaff