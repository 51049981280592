import React from 'react'
import './error.css'
import { useNavigate } from 'react-router-dom'

const Error = () => {

  const navigate = useNavigate();

  return (
    <div className='error-page'>
      <div className='error-box'>
        <h1>Error <span>404</span></h1>
        <h3>The <span>requested</span> page does not exist</h3>
        <button className='common-input-style' onClick={() => navigate('/home')}>Back to home</button>
      </div>
    </div>
  )
}

export default Error