
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import './userNavbar.css';
import { authToken } from '../../services/token';
import { handleLogOut } from '../../utils/helper';
import { USER_ROLES, ENVS } from '../../utils/constant';

const UserNavbar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const checksRemaining = useSelector(state => state.caseRemainingObj.casesRemaining);
    const token = authToken();
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

    const handleLogOutClick = () => {
        handleLogOut(dispatch, token, navigate);
    }

    return (
        <>
            <div className="user-navbar-container">
                <div className="logo">
                    <img
                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/logo.png`}
                        alt="logo"
                        className='logo'
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/home')}
                    />
                </div>
                <div className="user-navbar-elements">
                    <ul>
                        {localStorage.getItem('role') === USER_ROLES.BROKER && (
                            <>
                                <li onClick={() => navigate('/subscription')}>
                                    {checksRemaining === '0' ? 'Subscription' : 'Upgrade'}
                                    <img
                                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/subscription.png`}
                                        alt="upgrade"
                                        className='nav-img'
                                    />
                                </li>
                                <li onClick={() => navigate('/transaction')}>
                                    Transactions
                                    <img
                                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/transaction.png`}
                                        alt="transaction"
                                        className='nav-img'
                                    />
                                </li>
                                <li>
                                    <img
                                        src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/account.png`}
                                        alt="account"
                                        className='account-logout-image'
                                        onClick={() => navigate('/user-account')}
                                    />
                                </li>
                            </>
                        )}
                        <li>
                            <img
                                src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/logout.png`}
                                alt="logout"
                                className='account-logout-image'
                                onClick={handleLogOutClick}
                            />
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
    
}

export default UserNavbar