import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import Auth from './components/auth/Auth';
import Home from './pages/home/Home';
import './services/apis/authApi'
import Protected from './utils/Protected';
import CreateCase from './pages/createCase/CreateCase';
import DocsUpload from './pages/docsUpload/DocsUpload';
import Error from './components/error/Error';
import Report from './pages/report/Report';
import UserAccount from './pages/userAccount/UserAccount';
import Subscription from './pages/subscription/Subscription';
import Transaction from './pages/transaction/Transaction';
import Staff from './pages/staff/Staff';
import StaffHistory from './pages/staff/StaffHistory';
import CreateStaff from './pages/staff/CreateStaff';
import UpdateStaff from './pages/staff/UpdateStaff';
import SelfCasesHistory from './pages/cases/SelfCasesHistory';
import BrokerTeamCasesHistory from './pages/cases/BrokerTeamCasesHistory';
import CaseDetail from './pages/cases/CaseDetail';
import { USER_ROLES } from './utils/constant';
import ContactUs from './pages/contactUs/ContactUs';
import CaseBsTransactions from './pages/cases/CaseBsTransactions';

function App() {

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to='/login' />} />
          <Route path='/login' element={<Auth componentToRender='Login' />} />
          <Route path='/signup' element={<Auth componentToRender='SignUp' />} />
          <Route path='/home' element={(<Protected allowedRole='All'><Home /></Protected>)}/>
          <Route path='/create-case' element={(<Protected allowedRole='All'><CreateCase /></Protected>)}/>     
          <Route path='/cases-history' element={(<Protected allowedRole='All'><SelfCasesHistory /></Protected>)}/>  
          <Route path='/cases-history/staff' element={(<Protected allowedRole={USER_ROLES.BROKER}><BrokerTeamCasesHistory /></Protected>)}/>  
          <Route path='/case-detail' element={(<Protected allowedRole='All'><CaseDetail/></Protected>)}></Route>
          <Route path='/case-transactions' element={(<Protected allowedRole={'All'}><CaseBsTransactions/></Protected>)}></Route>
          <Route path='/docs-upload' element={(<Protected allowedRole='All'><DocsUpload/></Protected>)}/>   
          <Route path='/report' element={(<Protected allowedRole='All'><Report/></Protected>)}></Route>
          <Route path='/user-account' element={(<Protected allowedRole={USER_ROLES.BROKER}><UserAccount/></Protected>)}></Route>
          <Route path='/subscription' element={(<Protected allowedRole={USER_ROLES.BROKER}><Subscription/></Protected>)}></Route>
          <Route path='/transaction' element={(<Protected allowedRole={USER_ROLES.BROKER}><Transaction/></Protected>)}></Route>
          <Route path='/staff' element={(<Protected allowedRole={USER_ROLES.BROKER}><Staff/></Protected>)}></Route>
          <Route path='/staff-history' element={(<Protected allowedRole={USER_ROLES.BROKER}><StaffHistory/></Protected>)}></Route>
          <Route path='/create-staff' element={(<Protected allowedRole={USER_ROLES.BROKER}><CreateStaff/></Protected>)}></Route>
          <Route path='/update-staff' element={(<Protected allowedRole={USER_ROLES.BROKER}><UpdateStaff/></Protected>)}></Route>
          <Route path='/contact-us' element={(<ContactUs/>)}></Route>
          <Route path='*' element={<Error/>} />
         </Routes>
    </div>
  );

}

export default App;
