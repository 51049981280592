import axios from "axios";
import { URI_PREFIX, ENVS } from "../../utils/constant";

const {REACT_APP_REQUEST_URL, REACT_APP_ENVIRONMENT} = ENVS;

const REQUEST_URL = REACT_APP_REQUEST_URL;
const env = REACT_APP_ENVIRONMENT;
const prefix = URI_PREFIX[env];

export const fetchSingleUserApi = async (token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/user/detail`, token);
    return response;
}

export const updateUserApi = async (data, token) => {
    const response = await axios.put(`${REQUEST_URL}${prefix}/api/user/update`, data, token);
    return response;
}

export const fetchRemainingCasesApi = async (token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/user/remaining-cases`, token);
    return response;
}

export const contactUsApi = async (data, token) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/user/contact-us`, data, token);
    return response;
}