import axios from "axios";
import { URI_PREFIX, ENVS } from "../../utils/constant";

const {REACT_APP_REQUEST_URL, REACT_APP_ENVIRONMENT} = ENVS;

const REQUEST_URL = REACT_APP_REQUEST_URL;
const env = REACT_APP_ENVIRONMENT;
const prefix = URI_PREFIX[env];


export const fetchStaffApi = async (pageNo, filterData, token) => {

    const response = await axios.get(`${REQUEST_URL}${prefix}/api/broker/fetch-staff-details?page=${pageNo}&username=${filterData.username}&email=${filterData.email}&status=${filterData.status}`, token);
    return response;
}

export const createStaffApi = async (data, token) => {
    const response = await axios.post(`${REQUEST_URL}${prefix}/api/broker/create-staff`, data, token);
    return response;
}

export const fetchStaffMemberApi = async (id, token) => {

    const response = await axios.get(`${REQUEST_URL}${prefix}/api/broker/staff-member?staff_id=${id}`, token);
    return response;
}

export const updateStaffApi = async (id, data, token) => {

    const response = await axios.post(`${REQUEST_URL}${prefix}/api/broker/update-staff?staff_id=${id}`, data, token);
    return response;
}

export const updateStaffStatusApi = async (id, data, token) => {

    const response = await axios.put(`${REQUEST_URL}${prefix}/api/broker/update-staff-status?staff_id=${id}`, data, token);
    return response;
}

export const fetchStaffCasesApi = async (pageNo, token) => {
    const response = await axios.get(`${REQUEST_URL}${prefix}/api/broker/fetch-staff-cases?page=${pageNo}`, token);
    return response;
}

export const allocateCasesApi = async (id, data, token) => {

    const response = await axios.put(`${REQUEST_URL}${prefix}/api/broker/allocate-cases?staff_id=${id}`, data, token);
    return response;
}