const authToken = () => ({
  headers: {
    'Content-type': 'application/json',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
});

const authTokenFormData = () => ({
  headers: {
    'Content-type': 'multipart/form-data',
    Authorization: `Bearer ${localStorage.getItem('token')}`,
  }
})

export { authToken, authTokenFormData };