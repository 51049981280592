import React, { useState, useEffect } from 'react';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { authToken } from '../../services/token';
import './userAccount.css';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { fetchSingleUserApi, updateUserApi } from '../../services/apis/userApi';
import { toast } from 'react-toastify';
import { handleLogOut } from '../../utils/helper';
import { useNavigate } from 'react-router-dom';
import RemainingCase from '../../components/remainingCase/RemainingCase';
import { ENVS } from '../../utils/constant';

const UserAccount = () => {

    const dispatch = useDispatch();
    const token = authToken();
    const navigate = useNavigate();
    // const loading = useSelector((state) => state.utilsObj.loading);
    const [userData, setUserData] = useState({
        username: '',
        email: '',
        password: '',
        contact: '',
        address: ''
    });
    const [showAdditionalImage, setShowAdditionalImage] = useState(false);
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS

    useEffect(() => {
        userDetails();
    }, []);

    const handleSettingsClick = () => {
        setShowAdditionalImage(!showAdditionalImage);
    };

    const logoutHandler = () => {
        handleLogOut(dispatch, token, navigate);
    }

    const userDetails = async () => {

        try {

            dispatch(requestSent());

            const response = await fetchSingleUserApi(token);

            if (response.status === 200) {
                setUserData({
                    username: response.data.data.username,
                    email: response.data.data.email,
                    password: response.data.data.password,
                    contact: response.data.data.phone,
                    address: response.data.data.address
                })
                dispatch(responseRecieved());
                return;
            }

        } catch (error) {

            console.log('error', error);
            dispatch(recievedError());

            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            } else if (error.response.status === 403) {
                return navigate('/login')
            }
            return toast.error('Something went wrong');
        }
    }

    const handleUpdateSubmit = async (e) => {

        try {

            let data = {
                username: userData.username,
                email: userData.email,
                password: userData.password,
                contact: userData.contact,
                address: userData.address === '' ? null : userData.address
            }

            dispatch(requestSent());
            const response = await updateUserApi(data, token);

            if (response.status === 200) {
                userDetails();
                toast.success(response.data.message)
                dispatch(responseRecieved())
            }

        } catch (error) {
            console.log('error', error);
            dispatch(recievedError());

            if (error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            }

            return toast.error('Something went wrong');
        }
    }

    const handleUpdateChange = (e) => {

        const { name, value } = e.target;
        setUserData(prevState => ({
            ...prevState,
            [name]: value
        }))
    }



    return (
        <>
            <UserNavbar />
            <div className="user-account-page">

                <div className="user-account-container">
                    <div className='account-box-header'>
                        <div className='flex-div'>
                            <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" className='lessthan'
                                onClick={() => navigate(-1)} style={{ cursor: 'pointer' }}
                            /><h1>Account Details</h1>
                        </div>
                        <div className='custom-ml'>
                            <RemainingCase />
                        </div>
                    </div>

                    <div className="personal-details-container">
                        <h4 style={{ marginBottom: '10px' }}>Personal Details</h4>
                        <p style={{ marginBottom: '16px' }}>Update Your Personal Details</p>

                        <div className="update-box">
                            <div className="common-input-container">
                                <label htmlFor="username" className="update-label-style">Username</label>
                                <input type="text" name="username" className='broad-account-input' onChange={handleUpdateChange} value={userData.username} />
                            </div>
                            <div className="common-input-container">
                                <label htmlFor="email" className="update-label-style">Email</label>
                                <input type="text" name="email" className='broad-account-input' onChange={handleUpdateChange} value={userData.email} />
                            </div>
                            <div className="common-input-container">
                                <label htmlFor="password" className="update-label-style">Password</label>
                                <input type="password" name="password" className='broad-account-input' onChange={handleUpdateChange} value={userData.password} />
                            </div>
                            <div className="common-input-container">
                                <label htmlFor="contact" className="update-label-style">Contact</label>
                                <input type="number" name="contact" className='broad-account-input' onChange={handleUpdateChange} value={userData.contact} />
                            </div>
                            <div className="common-input-container">
                                <label htmlFor="address" className="update-label-style">Address</label>
                                <textarea type="text" name="address" className='broad-account-input text-area-case' rows={5} onChange={handleUpdateChange} value={userData.address} />
                            </div>
                            <button className='common-btn-style-2' onClick={handleUpdateSubmit}>Save</button>
                        </div>
                    </div>
                </div>

                <div className="settings-container">
                    <div className="settings-flex">
                        <p>Settings
                            <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/settings.png`} className='setting-img' alt='img' onClick={handleSettingsClick} />
                        </p>

                    </div>
                    {showAdditionalImage && (
                        <div className='setting-cloud'>
                            <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/settingscloud.png`} alt="additional" />
                            <div className='setting-cloud-content'>
                                <ul>
                                    <li onClick={() => navigate('/contact-us')}>Contact Us</li>
                                    <li onClick={() => navigate('/staff')}>Staff</li>
                                    <li onClick={logoutHandler}>Log out</li>
                                </ul>
                            </div>
                        </div>
                    )}
                </div>

            </div>
        </>
    )
}

export default UserAccount