import { configureStore } from '@reduxjs/toolkit'
import utilsSlice from '../slices/utilsSlice'
import subscriptionModalSlice from '../slices/subscriptionModalSlice'
import caseRemainingSlice from '../slices/caseRemainingSlice'
import addCaseModalSlice from '../slices/addCaseModalSlice'

export const store = configureStore({
  reducer: {
    utilsObj: utilsSlice,
    subscriptionModalObj: subscriptionModalSlice,
    caseRemainingObj: caseRemainingSlice,
    addCaseModalObj: addCaseModalSlice
  },
})