import React, { useEffect, useState } from 'react'
import { fetchRemainingCasesApi } from '../../services/apis/userApi';
import {authToken} from '../../services/token';

const RemainingCase = () => {

    const token = authToken();
    const [remainingCase, setRemainingCase] = useState(0);

    useEffect(() => {
        fetchRemainingCases();
    }, [remainingCase]);

    const fetchRemainingCases = async () => {
        try {
            const response = await fetchRemainingCasesApi(token);
            if (response.status === 200) {
                setRemainingCase(response.data.data);
                return
            }

        } catch (error) {
            
            console.log('error=>', error);
        }
    }

    return (
        <>
            <p className='remaining-cases'>Remaining cases: <span>{remainingCase}</span></p>
        </>
    )
}

export default RemainingCase