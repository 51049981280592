import { createSlice } from "@reduxjs/toolkit";

const subcriptionModalSlice = createSlice({
    name: 'subcriptionModalSlice',
    initialState:{
        isSubscripionModalSliceOpen: false,
        subscriptionDetails: null
    },
    reducers: {
        openSubscriptionModal: (state, action) => {
            state.isSubscripionModalSliceOpen = true
            state.subscriptionDetails = action.payload
        },
        closeSubscriptionModal: (state, action) => {
            state.isSubscripionModalSliceOpen = false
            // state.taskDetails = action.payload
        }
    }
});

export const {openSubscriptionModal, closeSubscriptionModal} = subcriptionModalSlice.actions;

export default subcriptionModalSlice.reducer;