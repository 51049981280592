import React, { useState } from 'react';
import './contactUs.css';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import { recievedError, requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { authToken } from '../../services/token';
import { toast } from 'react-toastify';
import { contactUsApi } from '../../services/apis/userApi';
import { useDispatch, useSelector } from 'react-redux';
import {ENVS} from '../../utils/constant';

const ContactUs = () => {

    const token = authToken();
    const dispatch = useDispatch();
    const loading = useSelector((state) => state.utilsObj.loading);
    const [data, setData] = useState({
        username: '',
        email: '',
        contact: '',
        message: ''
    })
    const {REACT_APP_FRONTEND_IMG_PATH} = ENVS;

    const handleInputChange = (e) => {

        const {name, value} = e.target;

        setData(prevState => ({
            ...prevState,
            [name]: value
        }))

    }

    const handleContactSubmit = async (e) => {
        e.preventDefault();

        try {

            if (data.contact === "") delete data.contact;

            dispatch(requestSent());
            const response = await contactUsApi(data, token);

            if (response.status === 200) {
                setData({
                    username: '',
                    email: '',
                    contact: '',
                    message: ''
                })
                dispatch(responseRecieved());
                return toast.success('Thanks for contacting our team will contact you shortly');
            }

        } catch (error) {

            console.log('error=>', error);
            dispatch(recievedError(error));
            if(error.response.status === 400 || error.response.status === 500) {
                return toast.error(error.response.data.error);
            }
            toast.error('Something went wrong');
        }
    }

    return (
        <>
            <UserNavbar />
            <div className="common-page">
                <div className="contact-box">
                    <div className="contact-left">
                        <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/images/contactus.png`} alt="contact-us" />
                    </div>
                    <div className="contact-right">
                        <h1 className='contact-heading'>Get In Touch</h1>
                        <div className="contact-form-container">
                        <p className='case-one-liner'>Feel free to query us</p>
                            <form method="post" onSubmit={handleContactSubmit}>
                                <div className='username-email-container'>
                                    <div className="username-input">
                                        <label htmlFor="username">Username</label>
                                        <input type="text" name='username' placeholder='Enter Username' value={data.username} onChange={handleInputChange} required/>
                                    </div>
                                    <div className="email-input">
                                        <label htmlFor="email">Email</label>
                                        <input type="email" name='email' placeholder='Enter Email' value={data.email} onChange={handleInputChange} required/>
                                    </div>
                                </div>
                                <div className="contact-input">
                                    <label htmlFor="contact">Contact</label>
                                    <input type="number" name='contact' placeholder='Enter Contact' value={data.contact} onChange={handleInputChange}/>
                                </div>
                                <div className="message-input">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message" className='message-textarea' placeholder='Enter Message' value={data.message} onChange={handleInputChange} required></textarea>
                                </div>
                                <button className={!loading ? 'common-btn-style' : 'common-btn-disabled'} disabled={loading ? true : false} >
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactUs