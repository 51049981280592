import React from 'react'
import './auth.css'
import Navbar from '../navbar/Navbar'
import Login from '../../pages/login/Login'
import SignUp from '../../pages/signup/SignUp'
import Error from '../error/Error'


const Auth = ({componentToRender}) => {

  return (
    <>
      <div className="auth-page-container">
        <Navbar />
        {componentToRender === 'Login' ? <Login /> : componentToRender === 'SignUp' ? <SignUp /> : <Error />}
      </div>
    </>
  )
}

export default Auth