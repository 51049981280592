import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { USER_ROLES } from './constant';
import { authToken } from '../services/token';
import { verifyUserStatusApi } from '../services/apis/authApi';
import { toast } from 'react-toastify';

const Protected = ({ children, allowedRole }) => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const token = authToken();

    const verifyUserStatus = async () => {

        try {

            const response = await verifyUserStatusApi(token);
            if(response.status === 200) {
                console.log('verified')
                return;
            }

        } catch (error) {
            
            console.log('error=>', error);
            if (error.response.status === 403) {
                return navigate('/login')
            } else {
                return toast.error('Something went wrong')
            }
        }
    }

    useEffect(() => {
        const tokenExist = localStorage.getItem('token');
        const storedRole = localStorage.getItem('role');

        if (tokenExist) {

            verifyUserStatus();
            setIsAuthenticated(true);
            if (allowedRole === USER_ROLES.BROKER && storedRole === USER_ROLES.STAFF) navigate('/home')

        } else {
            navigate('/login');
        }
    }, [navigate]);

    return isAuthenticated ? children : null;
};

export default Protected;
