import React from 'react';
import './filterBox.css'

const FilterBox = ({ filterData, handleFilterChange, handleSearchSubmit }) => {
  return (
    <div className="filter-box">
      <div className="input-box">
        <input
          type="text"
          placeholder='Search by username'
          name='username'
          className='filter-input'
          onChange={handleFilterChange}
          value={filterData.username}
        />
      </div>
      <div className="input-box">
        <input
          type="email"
          placeholder='Search by email'
          name='email'
          className='filter-input'
          value={filterData.email}
          onChange={handleFilterChange}
        />
      </div>
      <div className="select-box">
        <select name="status" className='filter-input' onChange={handleFilterChange}>
          <option value="">All Users</option>
          <option value="Active">Active</option>
          <option value="Blocked">Blocked</option>
          <option value="Deleted">Deleted</option>
        </select>
      </div>
      <div className="button-box">
        <button type='button' className='filter-submit' onClick={handleSearchSubmit}>Search</button>
      </div>
    </div>
  );
};

export default FilterBox;