import { createSlice } from "@reduxjs/toolkit";

const caseRemainingSlice = createSlice({
    name: 'caseRemainingSlice',
    initialState:{
       casesRemaining: localStorage.getItem('caseRemaining')
    },
    reducers: {
        setRemainingCase: (state, action) => {
            state.casesRemaining = String(action.payload);
            localStorage.setItem('caseRemaining', action.payload);
        },
        getRemainingCase: (state, action) => {
            const temp = localStorage.getItem('caseRemaining')
            state.casesRemaining = temp;
        }
    }
});

export const {setRemainingCase, getRemainingCase} = caseRemainingSlice.actions;

export default caseRemainingSlice.reducer;