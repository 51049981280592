// PayPalConfig.js

import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import {ENVS} from '../utils/constant';

const enviroment = ENVS.REACT_APP_ENVIRONMENT;
const {REACT_APP_PAYPAL_PROD_CLIENT_ID, REACT_APP_PAYPAL_SANDBOX_CLIENT_ID} = ENVS;

const PayPalConfig = ({ children }) => {
  return (
    <PayPalScriptProvider options={{ 
        clientId: enviroment === "production" ? REACT_APP_PAYPAL_PROD_CLIENT_ID : REACT_APP_PAYPAL_SANDBOX_CLIENT_ID,
        currency: "GBP",
        intent: "capture",
    }}>
      {children}
    </PayPalScriptProvider>
  );
};

export default PayPalConfig;
