import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import UserNavbar from '../../components/userNavbar/UserNavbar';
import './staff.css';
import { authToken } from '../../services/token';
import { requestSent, responseRecieved } from '../../redux/slices/utilsSlice';
import { fetchStaffApi } from '../../services/apis/brokerApi';
import FilterBox from '../../components/filterBox/FilterBox';
import { toast } from 'react-toastify';
import { ENVS } from '../../utils/constant';
import Loader from '../../components/loader/Loader';

const StaffHistory = () => {

  const navigate = useNavigate();
  const token = authToken();
  const dispatch = useDispatch();

  const [staffData, setStaffData] = useState([]);

  const [paginationData, setPaginationData] = useState({
    pageNo: 1,
    limit: 10,
    totalPages: 0
  });

  const [filterData, setFilterData] = useState({
    username: "",
    email: "",
    status: ""
  });
  const loading = useSelector((state) => state.utilsObj.loading);
  const { REACT_APP_FRONTEND_IMG_PATH } = ENVS


  useEffect(() => {
    fetchStaffHistory()
  }, [paginationData.pageNo]);


  const fetchStaffHistory = async (req, res) => {

    try {

      dispatch(requestSent());
      const response = await fetchStaffApi(paginationData.pageNo, filterData, token);
      if (response.status === 200) {

        setStaffData(response.data.data.rows);

        setPaginationData({
          pageNo: response.data.pageNo,
          limit: response.data.perPage,
          totalPages: response.data.totalPages
        });

        dispatch(responseRecieved())
      }

    } catch (error) {

      console.log('error=>', error);
      dispatch(responseRecieved(error));
      if (error.response.status === 400 || error.response.status === 500) {
        return toast.error(error.response.data.error);
      } else if (error.response.status === 403) {
        return navigate('/login')
      }
      return toast.error('Something went wrong');
    }
  }

  const handleFilterChange = (e) => {
    const { name, value } = e.target;

    setFilterData(prevState => ({
      ...prevState,
      [name]: value
    }))
  }

  const handleSearchSubmit = () => {
    fetchStaffHistory()
  }

  const handlePageClick = (data) => {
    const updatedPageNo = data.selected + 1; // Pagination starts from 0, so adding 1
    setPaginationData({ ...paginationData, pageNo: updatedPageNo });
  };

  return (

    loading ? <Loader /> : (
      <>
        <UserNavbar />

        <div className="common-page">

          <div className='account-box-header'>
            <div className='flex-div'>
              <img src={`${REACT_APP_FRONTEND_IMG_PATH}/assets/icons/back-arrow.png`} alt="lessthan" style={{ marginRight: '16px', cursor: 'pointer' }} onClick={() => navigate(-1)} />
              <h1>Staff Activity History</h1>
            </div>
          </div>

          <FilterBox filterData={filterData} handleFilterChange={handleFilterChange} handleSearchSubmit={handleSearchSubmit} />

          <div className="table-container">
            {
              staffData.length === 0 ? (
                <div className="no-data">
                  <h1>No Staff History Found</h1>
                </div>
              ) : (
                <table className="transaction-table">
                  <thead>
                    <tr className='table-row'>
                      <th className='table-head'>S.No.</th>
                      <th className='table-head'>Staff</th>
                      <th className='table-head'>Email</th>
                      <th className='table-head'>Total case created</th>
                      <th className='table-head'>Total cases approved</th>
                      <th className='table-head'>Total cases rejected</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      staffData.map((val, index) => {
                        return (
                          <tr className='table-row' key={index}>
                            <td className='table-data'>{index + 1 + (paginationData.pageNo - 1) * paginationData.limit}</td>
                            <td className='table-data'>{val?.username}</td>
                            <td className='table-data'>{val?.email}</td>
                            <td className='table-data'>{val?.cases_created}</td>
                            <td className='table-data'>{val?.cases_approved}</td>
                            <td className='table-data'>{val?.cases_rejected}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              )
            }

            {
              staffData.length > 0 &&
              <ReactPaginate
                pageCount={paginationData.totalPages}
                pageRangeDisplayed={2}
                marginPagesDisplayed={3}
                forcePage={paginationData.pageNo - 1 }
                onPageChange={handlePageClick}
                containerClassName={'pagination'}
                activeClassName={'active'}
              />
            }
          </div>

        </div>
      </>

    )
  )
}

export default StaffHistory